
import { defineComponent } from 'vue';
export default defineComponent({
	data(): any {
		return {
			allCourses: [],
			defaultImageUrl: 'https://picsum.photos/id/237/200/300'
		};
	},
	methods: {
		getAllCourses() {
			this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/courses/getAll`).then((res: any) => {
				this.allCourses = res.data;
			});
		},
		onedit(course: any): any {
			this.$router.push({ name: 'edit-course', params: { id: course._id } });
		}
	},
	async mounted() {
		await this.getAllCourses();
	}
});
