
import { defineComponent } from 'vue';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
export default defineComponent({
	props: ['propData'],
	mixins: [globalApiMixin],
	data(): any {
		return {
			dataPopulated: false,
			templatePayload: {},
			isValidation: false,
			checkedoptions: [],
			showResetbutton: true
		};
	},
	async mounted() {
		await this.fetchData();
		this.onReset();
	},

	methods: {
		backgroundImages(payload: any) {
			if (payload.imageUrl != '') {
				return {
					backgroundImage: `url("${payload.imageUrl}")`
				};
			} else {
				return {
					backgroundImage: `url("${process.env.VUE_APP_COMMON_IMAGE}")`
				};
			}
		},
		onSave(options: any) {

			console.log("dddd",options)
			for (var i = 0; i < options.length; i++) {
				for (var j = 0; j < this.checkedoptions.length; j++) {
					if (options[i].isCorrect == true) {
						options[i].isRight = true;
						
					}
					if (options[i].isCorrect == false) {
						options[i].isWrong = true;
					}
				}
			}
		},
		onReset() {
			this.checkedoptions = [];
			for (var i = 0; i < this.templatePayload.options.length; i++) {
				this.templatePayload.options[i].isRight = false;
				this.templatePayload.options[i].isWrong = false;
			}
		},

		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
				this.dataPopulated = true;
			} else {
				await this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					var listOfOptions = response.data[0].data;
					for (var i = 0; i < listOfOptions.options.length; i++) {
						listOfOptions.options[i].isRight = false;
						listOfOptions.options[i].isWrong = false;
					}
					this.dataPopulated = true;
				});
			}
		}
	}
});
