
import { defineComponent } from 'vue';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';

export default defineComponent({
	props: ['propData'],
	mixins: [globalApiMixin],
	data(): any {
		return {
			dataPopulated: false,
			templatePayload: {}
		};
	},
	mounted() {
		this.fetchData();
	},
	watch: {
		propData: function (newVal, oldVal) {
			// [{id: '2', text: 'test2'}]
			console.log('Prop changed: ', newVal, ' | was: ', oldVal);
			this.$forceUpdate();
		}
	},
	methods: {
		backgroundImages(payload: any) {
			if (payload.imageUrl != '') {
				return {
					backgroundImage: `url("${payload.imageUrl}")`
				};
			} else {
				return {
					backgroundImage: `url("${process.env.VUE_APP_COMMON_IMAGE}")`
				};
			}
		},
		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
				this.dataPopulated = true;
			} else {
				await this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					this.dataPopulated = true;
				});
			}
		}
	}
});
