
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
	data(): any {
		return {
			totalQuestions: [],
			questionaire: [],
			dataArray: [],
			counter: -1,
			checkedoptions1: [],
			question: [],
			next: 0,
			questionBlock: false,
			showText:true,
			picked: [],
			hideQuestions: false,
			isSelectedValue: [],
			selectedAnswerIndexes: [],
			selectedAnswerText: [],
			sampleData: [],
			singleCourse: [],
			sampletruearray: [],
			checkedarray: [],
			scorecard: false,
			testResult: [],
			error: false
		};
	},
	computed:{
		...mapGetters({ userInfo: 'userInfo' })
	},
	methods: {
		idExists(questionId: any) {
			return this.dataArray.some(function (el: any) {
				return el._id === questionId;
			});
		},
		onExit() {
			window.close();
		},
		modules(type: any) {
			 if (type == 'startExam') {
				location.reload();
				// this.$router.push({ name: 'test-screen', params: { courseId: this.$route.params.courseId } });
			}else {
				this.$router.push({ name: 'course-modules', params: { courseId: this.$route.params.courseId } })

			}
		},

		getRandom(arr: any, n: any) {
			var result;

			result = new Array(n);
			var len = arr.length;
			var taken = new Array(len);

			while (n--) {
				var x = Math.floor(Math.random() * len);
				result[n] = arr[x in taken ? taken[x] : x];
				taken[x] = --len in taken ? taken[len] : len;
			}

			return result;
		},

		onsavedData() {
			this.allQuestions = [];

			for (var i = 0; i < this.singleCourse[0].modules.length; i++) {
				let Questionairearray = 'questionaire' in this.singleCourse[0].modules[i];
				if (Questionairearray) {
					for (var j = 0; j < this.singleCourse[0].modules[i].questionaire.length; j++) {
						this.allQuestions.push(this.singleCourse[0].modules[i].questionaire[j]);
					}
				}
			}
			if (this.allQuestions.length <= 20) {
				this.sampleData = this.allQuestions;
			} else {
				var finaldata = this.getRandom(this.allQuestions, 20);
				this.sampleData = finaldata;
			}
		},

		remove(arr: any, item: any) {
			var index = arr.indexOf(item);
			return [
				// part of the array before the given item
				...arr.slice(0, index),

				// part of the array after the given item
				...arr.slice(index + 1)
			];
		},
	

		async onSubmit() {
			this.scorecard = true;
			this.showText=false,
			(this.hideQuestions = false), console.log(this.dataArray);
			let totalquestionandselectedvalues={
				totalQuestions:this.totalQuestions.length,
				dataArray:this.dataArray
			}
			await this.$http.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/${this.userInfo.organisationId}/user/${this.userInfo._id}/course/${this.$route.params.courseId}/questionairescreens/result`, totalquestionandselectedvalues).then((res: any) => {
				this.testResult = res.data;
				var resultPercentage = Math.round((this.testResult.pass / this.testResult.total) * 100);
				if (resultPercentage < 80) {
					this.error = true;
				} else {
					this.error = false;
				}
				// alert(JSON.stringify({ totalQuestions: this.testResult.total, right: this.testResult.pass, wrong: this.testResult.fail }));
			});
		},
		checkedoptions(optionIndex: number, question: any, e: any) {
			var presentId = this.idExists(question._id);
			var index = this.dataArray.findIndex((obj: any) => obj._id == question._id);

			if (question.questionaireType == 'ChecBoxQuestionType') {
				if (e.target.checked) {
					question.selectedAnswerIndexes.push(optionIndex);
				} else {
					var finalOptionSelected = this.remove(question.selectedAnswerIndexes, optionIndex);
					question.selectedAnswerIndexes = finalOptionSelected;
				}
				if (presentId) {
					this.dataArray[index].vindex = question.selectedAnswerIndexes;
				} else {
					this.dataArray.push({ _id: question._id, vindex: question.selectedAnswerIndexes, questionType: question.questionaireType });
				}
			} else {
				var selectedOption = [];
				selectedOption.push(optionIndex);
				if (presentId) {
					this.dataArray[index].vindex = selectedOption;
				} else {
					this.dataArray.push({ _id: question._id, vindex: selectedOption, questionType: question.questionaireType });
				}
			}
		},
		onPrevious() {
			this.alreadySelected = [];
			this.counter = this.counter - 1;
			var questionIndex = this.totalQuestions[this.counter];
			var checkId = this.idExists(questionIndex._id);
			var optionsPropertyCheck = questionIndex.options;
			var dataindex = this.dataArray.findIndex((x: any) => x._id == questionIndex._id);
			if (questionIndex.questionaireType == 'ChecBoxQuestionType') {
				if (checkId) {
					optionsPropertyCheck.forEach((element: any) => {
						delete element.alreadySelectedValue;
					});
					var optionCheckedIndex = [];

					for (var i = 0; i < this.dataArray[dataindex].vindex.length; i++) {
						optionCheckedIndex = this.dataArray[dataindex].vindex[i];
						this.totalQuestions[this.counter].options[optionCheckedIndex].alreadySelectedValue = true;
					}
					this.question = this.totalQuestions[this.counter];
				} else {
					this.question = this.totalQuestions[this.counter];
				}
			} else {
				if (checkId) {
					var optionCheckedIndexs = this.dataArray[dataindex].vindex;
					this.totalQuestions[this.counter].options[optionCheckedIndexs].alreadySelected = true;
					this.question = this.totalQuestions[this.counter];
				} else {
					this.question = this.totalQuestions[this.counter];
				}
			}
		},

		onNext() {
			// window.open(`${window.location.origin}/org/course/61fb7c6185781a3180682623/test`, '_blank');
			this.alreadySelected = [];
			this.hideQuestions = true;
			this.counter = this.counter + 1;
			var questionId = this.totalQuestions[this.counter];
			var checkId = this.idExists(questionId._id);
			var optionsPropertyCheck = questionId.options;
			var dataindex = this.dataArray.findIndex((x: any) => x._id == questionId._id);
			if (questionId.questionaireType == 'ChecBoxQuestionType') {
				if (checkId) {
					optionsPropertyCheck.forEach((element: any) => {
						delete element.alreadySelectedValue;
					});
					var optionCheckedIndex = [];

					for (var i = 0; i < this.dataArray[dataindex].vindex.length; i++) {
						optionCheckedIndex = this.dataArray[dataindex].vindex[i];
						this.totalQuestions[this.counter].options[optionCheckedIndex].alreadySelectedValue = true;
					}

					this.question = this.totalQuestions[this.counter];
				} else {
					this.question = this.totalQuestions[this.counter];
				}
			} else {
				if (checkId) {
					optionsPropertyCheck.forEach((element: any) => {
						delete element.alreadySelected;
					});
					var optionCheckedIndexs = this.dataArray[dataindex].vindex;
					this.totalQuestions[this.counter].options[optionCheckedIndexs].alreadySelected = true;
					this.question = this.totalQuestions[this.counter];
				} else {
					this.question = this.totalQuestions[this.counter];
				}
			}
		},

		async getSingleCourse() {
			this.spinnerLoading = true;
			await this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/course/${this.$route.params.courseId}/get`).then((res: any) => {
				this.singleCourse = res.data;
			});
			await this.onsavedData();
		},
		async getAllQuestions() {
			await this.$http.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/course/${this.$route.params.courseId}/questionairescreens/getAll`, this.sampleData).then((res: any) => {
				this.totalQuestions = res.data;
				for (var i = 0; i < this.totalQuestions.length; i++) {
					if (this.totalQuestions[i].questionaireType == 'ChecBoxQuestionType') {
						this.totalQuestions[i].checked = [];
						this.totalQuestions[i].selectedAnswerIndexes = [];
					} else {
						this.totalQuestions[i].picked = [];
					}
				}
			});
		}
	},

	async mounted() {
		await this.getSingleCourse();
		await this.getAllQuestions();
	}
});
