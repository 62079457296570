
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import spinner from '../../components/spinner.vue';
import { toast } from '../../main';
import { requiredIf } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
export default defineComponent({
	components: {
		spinner
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' })
	},

	data(): any {
		return {
			v$: useVuelidate(),
			screens: [],
			showvalidation: false,
			showvalidationforaccordions: false,
			spinnerLoading: false,
			screenNameValidation: false,
			totalScreens: [],
			optionListIndex: '',
			updateButton: false,
			updatescreen: false,
			newScreen: false,
			checkIds:[],
			course:'',
			moduleName:'',
			imageUrl:''
		};
	},

	validations(smIndex: any) {
		return {
			screens: {
				$each: helpers.forEach({
					screenName: { required },
					screenType: { required },
					headerLabel: { required },
					headerData: { required }
				})
			}
		};
	},
	methods: {
		changedInputControlValue(screen: any) {
			console.log('logged');
			if (screen._id) {
				screen.updatescreen = true;
			} else {
				screen.newScreen = true;
			}
		},
		resetData(screen: any) {
				screen.screenName= 'Screen',
				screen.screenType= '',
				screen.isActive= false,
				screen.order= '',
				screen.backgroundimage= '',
				screen.color= 'red',
				screen.headerLabel= '',
				screen.headerData='',
				screen.optionsLabel= '',
				screen.optionsData= '',
				screen.options= [],
				screen.accordions= [],
				screen.textbullets=[],
				screen.tabs= [],
				screen.correctAnswer= false,
				screen.answerVisibility= true,
				screen.validationScreenName= false,
				screen.isShowPreview= false,
				screen.toggleSourceInput= false,
				screen.audioUrl= '',
				screen.imageUrl= ''
		},
		addScreen(): any {
			this.screens.push({
				screenName: 'Screen',
				screenType: '',
				isActive: false,
				order: '',
				backgroundimage: '',
				color: 'red',
				headerLabel: '',
				headerData: '',
				optionsLabel: '',
				optionsData: '',
				options: [],
				accordions: [],
				textbullets:[],
				tabs:[],
				correctAnswer: false,
				answerVisibility: true,
				validationScreenName: false,
				isShowPreview:false,
				toggleSourceInput: false,
				audioUrl: '',
				imageUrl: ''
			});
		},
		preview(screenSelection: any, screenId: any): any {
			if (screenSelection == 'TextBoxScreen') {
				console.log(window.location.origin);
				window.open(`${window.location.origin}/admin/template/text/${screenId}`, '_blank');
			} else if (screenSelection == 'RadioScreen') {
				window.open(`${window.location.origin}/admin/template/radio/${screenId}`, '_blank');
			} else if (screenSelection == 'CheckBoxScreen') {
				window.open(`${window.location.origin}/admin/template/checkbox/${screenId}`, '_blank');
			} else if (screenSelection == 'VerticallyAccordionScreen') {
				window.open(`${window.location.origin}/admin/template/vaccordion/${screenId}`, '_blank');
			} else if (screenSelection == 'HorizantallyAccordionScreen') {
				window.open(`${window.location.origin}/admin/template/haccordion/${screenId}`, '_blank');
			} else if (screenSelection == 'TabScreen') {
				window.open(`${window.location.origin}/admin/template/tab/${screenId}`, '_blank');
			}else if (screenSelection == 'TextWithBulletPointsScreen') {
				window.open(`${window.location.origin}/admin/template/textbullet/${screenId}`, '_blank');
			}
		},

		addOptions(moduleIndex: any, optionValue: string) {
			if (optionValue != '') {
				if (this.screens[moduleIndex].screenType == 'RadioScreen') {
					this.saveRadiooptions(moduleIndex);
				} else {
					this.saveCheckBoxOptions(moduleIndex);
				}
			}
		},

		onDelete(optionIndex: any, moduleIndex: any) {
			if (this.screens[moduleIndex].screenType == 'RadioScreen') {
				this.onradioDelete(optionIndex, moduleIndex);
			} else {
				this.onCheckBoxOptionsDelete(optionIndex, moduleIndex);
			}
		},
		onCheckBoxOptionsDelete(optionIndex: any, moduleIndex: any) {
			this.screens[moduleIndex].options.splice(optionIndex, 1);
		},
		backtocourseEdit() {
			// console.log("Ssss",this.$route.params)
			this.$router.push({ name: 'edit-course', params: { id: this.$route.params.courseId } });
		},
		saveCheckBoxOptions(moduleIndex: any): any {
			this.screens[moduleIndex].options.push({
				label: this.screens[moduleIndex].optionsData,
				isCorrect: this.screens[moduleIndex].correctAnswer || false
			});
			(this.screens[moduleIndex].correctAnswer = ''), (this.screens[moduleIndex].optionsData = '');
		},
			async addOrUpdateScreen(moduleIndex: any){
			delete this.screens[moduleIndex].updatescreen;
			delete this.screens[moduleIndex].newScreen;
			this.screens[moduleIndex]['isActive'] =true;
			this.screens[moduleIndex].validationScreenName = false;
			const uniqueValues = new Set(this.screens.map((v: any) => v.screenName));
			if (uniqueValues.size < this.screens.length) {
				this.screens[moduleIndex].validationScreenName = true;
			}else{
				this.screens[moduleIndex].isShowPreview = true
			}
		let errorCondition = this.v$.screens.$each.$message[moduleIndex];
			// stop here if form is invalid
			if (errorCondition.length == 0 && !this.screens[moduleIndex].validationScreenName) {
				this.v$.$reset();
				var finalpayload;
				// delete this.screens[moduleIndex]['isActive'];
				let date = new Date();
				finalpayload = {
					screens: [
						{
							createdBy: this.userInfo.sub,
							updatedBy: this.userInfo.sub,
							createdAt: date,
							updatedAt: date,
							screenName: this.screens[moduleIndex].screenName,
							screenType: this.screens[moduleIndex].screenType,
							order: this.screens[moduleIndex].order,
							screenStyles: {
								backgroundimage: this.screens[moduleIndex].backgroundimage,
								color: this.screens[moduleIndex].color
							},
							data: {
								headerLabel: this.screens[moduleIndex].headerLabel,
								headerData: this.screens[moduleIndex].headerData,
								optionsLabel: this.screens[moduleIndex].optionsLabel,
								optionsData: this.screens[moduleIndex].optionsData,
								options: this.screens[moduleIndex].options,
								accordions: this.screens[moduleIndex].accordions,
								textbullets:this.screens[moduleIndex].textbullets,
								tabs:this.screens[moduleIndex].tabs,
								imageUrl: this.imageUrl,
								audioUrl: this.screens[moduleIndex].audioUrl
							},
							_id: this.screens[moduleIndex]._id
						}
					]
				};
				if (this.screens[moduleIndex]._id == undefined) {
					this.saveScreens(finalpayload,moduleIndex);
				} else {
					this.updateScreen(finalpayload, this.screens[moduleIndex]._id);
				}
			
				 }
			},
		async createPayload(moduleIndex: any) {

			
			this.v$.$touch();
			if (!this.v$.$invalid) {
				let imageData=this.$refs[`element${moduleIndex}`].files[0]
				if(this.$refs[`element${moduleIndex}`].files[0] !=undefined ){
				  	if(imageData.type =='image/jpeg' || imageData.type =='image/jpg' || imageData.type =='image/png' || imageData.type =='image/svg'){
						await this.sendData(this.$refs[`element${moduleIndex}`].files[0])
						 this.addOrUpdateScreen(moduleIndex)
					}
					else {
					toast.error('Image Invalid Format', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
					});
				}
			}
				else{
					this.imageUrl=this.screens[moduleIndex].imageUrl
					 this.addOrUpdateScreen(moduleIndex)
					}
			} else {
				toast.error('validation failed', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			// this.screens[moduleIndex].isShowPreview = true;
			
			
				
		},
	
		async uploadImage (data:any) {
						return new Promise(resolve => {
						const reader:any = new FileReader()
						reader.onload = function () {
						resolve(reader.result.replace('data:', '')
						.replace(/^.+,/, '')) 
						}
						reader.readAsDataURL(data)
						})
					},
			 async sendData(data:any){
				this.spinnerLoading =true
					// console.log("$refs.fileInput.files[0]",this.$refs.fileInput.files[0])
					const imageFile = await this.uploadImage(data)
					let fileExtension=data.name.split('.').pop()
					let id=uuidv4();
					let findId =this.checkIds.filter((x:any) => x ===id )
					if(findId.length>0){
						id=uuidv4()
					}
					let payload={image:imageFile,name:`${id}.${fileExtension}`}
					try {
					await this.$http
					.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_FILE_MANAGEMENT_PATH}/bucket/${process.env.VUE_APP_TRAINING_API_PATH}/file/upload`, payload)
						 //.post(`http://localhost:4030/services/filemanagement/bucket/${process.env.VUE_APP_TRAINING_API_PATH}/file/upload`, payload)
						.then((response: any) => {
					if (response.status == 200) {
							console.log(response.data)
							this.imageUrl=response.data
							this.spinnerLoading=false
						} 
					else {
							console.log('response status', response.status);
						}
					})
					} catch (err) {
					console.error(err);
					}
			},

		saveScreens(finalpayload: any,moduleIndex:any) {
			
			const toast = useToast();
			// this.spinnerLoading = true;
			this.$http
				.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/screens/add`, finalpayload)
				.then((response: any) => {
					 this.screens[moduleIndex]._id=response.data.updatedScreenId[0]
					// this.getallScreens();
					toast.info('Screen Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				})
				.catch((error: any) => {
					console.log("ee",error)
					// this.spinnerLoading = false;
					toast.error('Screen not Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		onradioDelete(optionIndex: any, moduleIndex: any) {
			if (this.screens[moduleIndex].options[optionIndex].isCorrect == true) {
				this.screens[moduleIndex].answerVisibility = true;
			}
			this.screens[moduleIndex].options.splice(optionIndex, 1);
		},

		saveRadiooptions(moduleIndex: any): any {
			var visibility = this.screens[moduleIndex].correctAnswer;
			if (visibility == true) {
				this.screens[moduleIndex].answerVisibility = false;
			}
			this.screens[moduleIndex].options.push({
				label: this.screens[moduleIndex].optionsData,
				isCorrect: this.screens[moduleIndex].correctAnswer || false
			});
			(this.screens[moduleIndex].correctAnswer = ''), (this.screens[moduleIndex].optionsData = '');
		},

		saveVerticallyChildAccordionData(moduleIndex: any) {
			this.screens[moduleIndex].accordions.push({
				label: this.screens[moduleIndex].accordions.label,
				text: this.screens[moduleIndex].accordions.text,
				isActive: false
			});
			(this.updateButton = false), (this.screens[moduleIndex].accordions.label = ''), (this.screens[moduleIndex].accordions.text = '');
		},
		
		async saveTabData(moduleIndex: any) {

				if(this.$refs.fileInput.files[0] !=undefined){
				await this.sendData(this.$refs.fileInput.files[0]);
			}
			else{
				this.imageUrl=this.screens[moduleIndex].imageUrl
			}
			this.screens[moduleIndex].tabs.push({
				label: this.screens[moduleIndex].tabs.label,
				text: this.screens[moduleIndex].tabs.text,
				imageUrl: this.imageUrl,
				audioUrl:this.screens[moduleIndex].tabs.audioUrl,
				isActive: false
			});
			(this.updateButton = false), this.screens[moduleIndex].tabs.audioUrl = '',(this.screens[moduleIndex].tabs.label = ''), (this.screens[moduleIndex].tabs.text = ''), (this.screens[moduleIndex].tabs.imageUrl = '');			
		},

		async updateTabData(moduleIndex: any) {
					if(this.$refs.fileInput.files[0] !=undefined){
						console.log("if")
				await this.sendData(this.$refs.fileInput.files[0]);
			}
			else{
				console.log("else")
				this.imageUrl=this.screens[moduleIndex].tabs.imageUrl
			}
			console.log("imageUrl",this.imageUrl)
			this.screens[moduleIndex].tabs[this.optionListIndex].label = this.screens[moduleIndex].tabs.label,
			 this.screens[moduleIndex].tabs[this.optionListIndex].text = this.screens[moduleIndex].tabs.text;
			 this.screens[moduleIndex].tabs[this.optionListIndex].imageUrl = this.imageUrl
			  this.screens[moduleIndex].tabs[this.optionListIndex].audioUrl = this.screens[moduleIndex].tabs.audioUrl

			this.optionListIndex = '';
			this.screens[moduleIndex].tabs.label = '';
			this.screens[moduleIndex].tabs.text = '';
			this.screens[moduleIndex].tabs.imageUrl = '';
			this.screens[moduleIndex].tabs.audioUrl = '';
			this.updateButton = false;
		},
		onTabEdit(optionIndex: any, moduleIndex: any){
			this.optionListIndex = optionIndex;
			this.updateButton = true;
			console.log("this.screens[moduleIndex].tabs",this.screens[moduleIndex].tabs)
			this.screens[moduleIndex].tabs.label = this.screens[moduleIndex].tabs[optionIndex].label;
			this.screens[moduleIndex].tabs.text = this.screens[moduleIndex].tabs[optionIndex].text;
			this.screens[moduleIndex].tabs.imageUrl = this.screens[moduleIndex].tabs[optionIndex].imageUrl
			this.screens[moduleIndex].tabs.audioUrl = this.screens[moduleIndex].tabs[optionIndex].audioUrl
		},
		onTabDelete(optionIndex: any, moduleIndex: any) {
		this.screens[moduleIndex].tabs.splice(optionIndex, 1);
		},
		onVerticallyChildAccordionDelete(optionIndex: any, moduleIndex: any) {
			this.screens[moduleIndex].accordions.splice(optionIndex, 1);
		},
		updateVerticallyChildAccordionData(moduleIndex: any) {
			(this.screens[moduleIndex].accordions[this.optionListIndex].label = this.screens[moduleIndex].accordions.label), (this.screens[moduleIndex].accordions[this.optionListIndex].text = this.screens[moduleIndex].accordions.text);

			this.optionListIndex = '';
			this.screens[moduleIndex].accordions.label = '';
			this.screens[moduleIndex].accordions.text = '';
			this.updateButton = false;
		},
		updatetextbulletData(moduleIndex: any) {
			(this.screens[moduleIndex].textbullets[this.optionListIndex].text = this.screens[moduleIndex].textbullets.text);
			this.optionListIndex = '';
			this.screens[moduleIndex].textbullets.text = '';
			this.updateButton = false;
		},
		savetextbulletData(moduleIndex: any) {
			this.screens[moduleIndex].textbullets.push({
				text: this.screens[moduleIndex].textbullets.text,
				isActive: false
			});
			(this.updateButton = false),(this.screens[moduleIndex].textbullets.text = '');
		},
		ontextbulletDelete(optionIndex: any, moduleIndex: any) {
			console.log("sssss")
			this.screens[moduleIndex].textbullets.splice(optionIndex, 1);
		},
		ontextbulletEdit(optionIndex: any, moduleIndex: any){
			this.optionListIndex = optionIndex;
			this.updateButton = true;
			this.screens[moduleIndex].textbullets.text = this.screens[moduleIndex].textbullets[optionIndex].text;
		},
		onVerticallyChildAccordionEdit(optionIndex: any, moduleIndex: any) {
			this.optionListIndex = optionIndex;
			this.updateButton = true;
			this.screens[moduleIndex].accordions.label = this.screens[moduleIndex].accordions[optionIndex].label;
			this.screens[moduleIndex].accordions.text = this.screens[moduleIndex].accordions[optionIndex].text;
		},

		getallScreens() {
			this.totalScreens = [];
			this.screens = [];
			const toast = useToast();
			this.spinnerLoading = true;
			this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/screens/getAll`).then((res: any) => {
				this.spinnerLoading = false;
				this.totalScreens = res.data;

				for (var i = 0; i < this.totalScreens.length; i++) {
					this.screens.push({
						screenName: this.totalScreens[i].screenName,
						screenType: this.totalScreens[i].screenType,
						order: this.totalScreens[i].order,
						backgroundimage: this.totalScreens[i].backgroundimage,
						color: this.totalScreens[i].color,
						headerLabel: this.totalScreens[i].data.headerLabel,
						headerData: this.totalScreens[i].data.headerData,
						optionsLabel: this.totalScreens[i].data.optionsLabel,
						optionsData: this.totalScreens[i].data.optionsData,
						options: this.totalScreens[i].data.options,
						accordions: this.totalScreens[i].data.accordions,
						tabs: this.totalScreens[i].data.tabs,
						textbullets:this.totalScreens[i].data.textbullets,
						audioUrl:this.totalScreens[i].data.audioUrl,
						imageUrl: this.totalScreens[i].data.imageUrl,
						_id: this.totalScreens[i]._id
					});
				}
			});
			this.spinnerLoading = false;
		},
		updateScreen(singlescreen: any, singlescreenId: any) {
			delete singlescreen.screens[0]._id;
			var screenId = singlescreenId;
			singlescreen.screens[0].updatedBy = this.userInfo.sub;
			singlescreen.screens[0].updatedAt = new Date();
			const toast = useToast();
			this.spinnerLoading = true;
			this.$http
				.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/screen/${screenId}/update`, singlescreen.screens[0])
				.then((res: any) => {
					this.spinnerLoading = false;
					toast.info('Screen updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		async getSingleCourse() {
			console.log("ss")
			this.spinnerLoading = true;
			await this.$http
				.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/course/${this.$route.params.courseId}/get`)
				.then((res: any) => {
					this.course = res.data;
					console.log("sss",this.course)
					this.course.forEach((course:any) => {
					this.moduleName=course.modules.filter((module: any) => module._id == this.$route.params.moduleId);
						
					});
				})
				.catch((error: any) => {
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		async getallImagesfromS3(){
			try {
					await this.$http
					.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_FILE_MANAGEMENT_PATH}/bucket/files/getall`)
						 //.get(`http://localhost:4030/services/filemanagement/bucket/files/getall`)
						.then((response: any) => {
					if (response.status == 200) {
							for(var i=0;i<response.data.Contents.length;i++){
								let ids= response.data.Contents[i].Key.substring(0, response.data.Contents[i].Key.lastIndexOf('.'))
								this.checkIds.push(ids)
							}
						} 
					})
					} catch (err) {
					console.error(err);
					}

		},
	},
	async mounted() {
		this.getallImagesfromS3()
		console.log("ssslog")
		this.pagetype = this.$route.fullPath.includes('update') ? 'update' : 'edit';
		this.getallScreens();
		await this.getSingleCourse();
	}
});
