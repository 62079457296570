
import { defineComponent } from 'vue';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
export default defineComponent({
	props: ['propData'],
	mixins: [globalApiMixin],
	data(): any {
		return {
			isFlag: true,
			list: [],
			a:false,
			dataPopulated: false,
			templatePayload: {}
		};
	},
	computed:{
		changeWidth: function ():any{
			return this.a
		}
	},
	methods: {
		
		onopen(singleslide: any, index: any) {
			console.log("dhhdh")
			if (singleslide.isActive == true) {
				for (var i = 0; i < this.templatePayload.accordions.length; i++) {
					if (i == index) {
						this.isFlag = true;
						this.templatePayload.accordions[i].isActive = !this.templatePayload.accordions[i].isActive;
						this.a=this.templatePayload.accordions[i].isActive
					} else {
						this.templatePayload.accordions[i].isActive = false;
						
					}
				}
			} else {
				for (var j = 0; j < this.templatePayload.accordions.length; j++) {
					if (j == index) {
						this.isFlag = false;
						this.templatePayload.accordions[j].isActive = !this.templatePayload.accordions[j].isActive;
						this.a=this.templatePayload.accordions[j].isActive;
						console.log(this.a)
					
					} else {
						this.templatePayload.accordions[j].isActive = false;
						
					}
				}
			}
		},

		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
				this.dataPopulated = true;
			} else {
				await this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					this.dataPopulated = true;
				});
			}
		}
	},
	mounted() {
		this.fetchData();
	}
});
