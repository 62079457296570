
import { emitter } from '@/main';
import { defineComponent } from 'vue';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
export default defineComponent({
	mixins: [globalApiMixin],
	props: ['propData'],
	data(): any {
		return {
			dataPopulated: false,
			templatePayload: {},
			labels: [],
			data: [],
			filteredCourses: []
		};
	},
	async mounted() {
		await this.fetchData();
		this.toggleTabs(1);
	},
	methods: {
		toggleTabs(navIndex: any) {
			this.openTab = navIndex;
			emitter.emit('tabs', navIndex);
			this.filteredCourses = [];
			this.filteredCourses = this.data[navIndex];
		},
		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
				this.dataPopulated = true;
				this.templatePayload.tabs.forEach((tab: any) => {
					this.labels.push(tab.label), this.data.push(tab);
				});
			} else {
				await this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					this.templatePayload.tabs.forEach((tab: any) => {
						this.labels.push(tab.label), this.data.push(tab);
					});
				});
			}
		}
	}
});
