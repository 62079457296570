
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { toast } from '../../main';
import { alphaNum } from '@vuelidate/validators';
export default defineComponent({
	data(): any {
		return {
			modules: [],
			courseComplete: 30,
			arrays: [],
			circumference: '',
			strokeDashoffset: '',
			showExamModuleStatus:false,
			courseDetails:[],
			finalModules: [],
			moduledata:[],
			defaultImageUrl: 'https://picsum.photos/id/1005/400/250'
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
		modulesdata: function ():any{
return this.modules.filter((module:any)=> module.screens)
}
	},
	methods: {
		onedit(module: any): any {
			if (module.moduleId == '11111') {
				this.goToTest();
			} else {
				this.$router.push({ name: 'screens', params: { courseId: this.$route.params.courseId, moduleId: module._id, activeScreen: module.isCompleted ? 0 : module.completedScreens ? module.completedScreens : 0 } });
			}

			// window.open(routeData.href, '_blank');
		},
		onExit(){
			window.close()
		},
		async getUserActivityModules() {
			this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/user/${this.userInfo._id}/course/${this.$route.params.courseId}/userActivity`).then((res: any) => {
				this.modules = res.data;
			});
		},
		goToTest() {
			this.$router.push({ name: 'test-screen', params: { courseId: this.$route.params.courseId } });
			// var closedroute: any = window.open(routeData.href, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
		}
	},
	async created() {
		await this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/course/${this.$route.params.courseId}/modules/getAll`).then(async(res: any) => {
			await this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_TRAINING_API_PATH}/user/${this.userInfo._id}/course/${this.$route.params.courseId}/userActivity`).then((a: any) => {
				let userActivityModules = a.data[0];
				this.courseDetails =a.data[0];
				if(a.data[0].examStatus){
					a.data[0].examStatus.totalModules=a.data[0].examStatus ? a.data[0].examStatus.totalModules+1:a.data[0].examStatus.totalModules+0
					var resultPercentage = Math.round((a.data[0].examStatus.pass / a.data[0].examStatus.total) * 100);
					if (resultPercentage < 80) {
						 this.showExamModuleStatus =false
						} else {
							this.showExamModuleStatus =true;
						}
					
				}else{
					this.showExamModuleStatus =false;
				}
				if (userActivityModules && userActivityModules.modules) {
					this.modules = res.data.map((module: any, index: number) => {
						let filteredModule = userActivityModules.modules.find((aModule: any) => {
							return aModule.moduleId == module._id;
						});
						if (filteredModule) {
							module.isActive = filteredModule.statusCodeId == 10402;
							module.isCompleted = filteredModule.statusCodeId == 10403;
							module.totalScreens = filteredModule.totalScreens;
							module.completedScreens = filteredModule.completedScreens;
						} else {
							module.isActive = false;
							module.isCompleted = false;
							module.totalScreens = 0;
							module.completedScreens = 0;
						}
						if (index != 0) {
							if (res.data[index - 1].isCompleted && !module.isCompleted) module.isActive = true;
						}
						return module;
					});
					let showExamModule =this.modules
					this.modules =	this.modules.filter((module:any)=> module.screens )
					let exam=this.modules.filter((module:any)=> module.questionaire )
					if(showExamModule.length==this.modules.length && exam.length>0){
						this.modules.push({
						moduleName: 'Test',
						isCompleted:this.showExamModuleStatus,
						moduleId:'11111',
						completedScreens:100,
						totalScreens:100,
						createdBy: '',
						createdAt: '',
						updatedBy: '',
						updatedAt: '',
						screens:['1334556577']
					});
					}		
				} else {
					this.modules = res.data.map((v: any) => ({ ...v, isActive: false, isCompleted: false }));
					let showExamModule =this.modules
					this.modules =	this.modules.filter((module:any)=> module.screens )
						let exam=this.modules.filter((module:any)=> module.questionaire )
					if(showExamModule.length==this.modules.length&& exam.length>0){
						this.modules.push({
						moduleName: 'Test',
						moduleId:'11111',
						isCompleted:this.showExamModuleStatus,
						completedScreens:100,
						totalScreens:100,
						createdBy: '',
						createdAt: '',
						updatedBy: '',
						updatedAt: '',
						screens:['1334556577']
					});
					}
					// 	console.log("sjhsh")
					// this.modules.forEach((module: any) => {
					// 	console.log("module",module);
					// 	if (module.screens) {
					// 		this.finalModules.push(module);
					// 	}
					// });
					// this.modules.push({
					// 	moduleName: 'Start Exam',
					// 	moduleId:'11111',
					// 	createdBy: '',
					// 	createdAt: '',
					// 	updatedBy: '',
					// 	updatedAt: ''
					// });
					// console.log('aasss', this.finalModules);
				}
			});
		});
	}
});
