
import { defineComponent } from 'vue';
export default defineComponent({
	props: ['currentModule'],
	data(): any {
		return {
			moduleIndex: '',
			nextModules: '',
			showExam:false,
			courseModules:[],

		};
	},
	methods:{
		modules(type: any) {
			if (type == 'nextmodule') {
				this.$emit('module', 'nextmodule');
			} else if (type == 'currentmodule') {
				this.$emit('module', 'currentmodule');
			} 
			 else if (type == 'startExam') {
				this.$emit('module', 'startExam');
			}else {
				this.$emit('module', 'modules');
			}
		}
	},
	mounted(){
		this.moduleIndex=this.currentModule.moduleIndex+1;
		this.courseModules = this.currentModule.courses.modules.filter((module: any) => module.screens);
		let exam=this.currentModule.courses.modules.filter((module:any)=> module.questionaire )
		if(exam.length>0){
			this.showExam = true;
		}else{
			this.showExam = false;
		}
	}
	
});
